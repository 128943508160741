.btn-transition {
  transition: background-color 0.1s ease-in-out 0s;
}

.btn-danger-hover {
  @extend .btn-transition;
  &:hover {
    background-color: rgb($danger, 0.75);
  }
}

.icon-danger-hover {
  @extend .btn-transition;
  &:hover {
    color: rgb($danger, 0.75) !important;
  }
}

.multiform-add-button {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .btn;
  @extend .border;
  @extend .w-100;

  transition: 0.3s;

  &:not([aria-disabled="true"]) {
    cursor: pointer;
    &:hover {
      color: rgb(243, 244, 246);
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #dfdfdf;
    &:active {
      pointer-events: none;
    }
  }
}

.action-button {
  transition: 0.3s;

  &:not([aria-disabled="true"]) {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
    &:active {
      pointer-events: none;
    }
  }
}

.delete-action-button {
  @extend .action-button;
  svg {
    transition: 0.3s;
    color: map-get($danger-color, "base") !important;
  }

  &:not([aria-disabled="true"]) {
    &:hover {
      svg {
        color: map-get($danger-color, "lighten-4") !important;
      }
    }
  }
}
