$form_step_content_transform: 50px;
$form_step_content_animation_delay: 0.2s;

@mixin step-content-moving($direction) {
  @if $direction == "prev" {
    animation: reverse-moving $form_step_content_animation_delay ease forwards;
  } @else {
    animation: moving $form_step_content_animation_delay ease forwards;
  }
}

.form-step-content {
  @extend .mx-auto;
  display: none !important;

  &.form-step-content-active {
    display: flex !important;
    .step-content-container {
      display: flex !important;
    }
  }

  &.form-step-content-prev {
    .step-content-container {
      @include step-content-moving("prev");
      @keyframes reverse-moving {
        from {
          transform: translateY(-$form_step_content_transform);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &.form-step-content-next {
    .step-content-container {
      @include step-content-moving("next");

      @keyframes moving {
        from {
          transform: translateY($form_step_content_transform);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

.dark-layout {
  .form-tab-container {
    background-color: $theme-dark-body-bg;
  }
  .form-tab-wrapper-active {
    background-color: #2b2d31;
  }
}
.form-tab-container {
  background-color: $body-bg;

  @extend .border-bottom;
  @include media-breakpoint-up(lg) {
    border: none !important;
  }
}

.form-tab-nav-wrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
  @extend .border-bottom;
  @include media-breakpoint-down(lg) {
    border: none !important;
  }

  &:hover {
    opacity: 0.5;
  }
}
.form-tab-nav-wrapper-active {
  cursor: default;
  background-color: white;
  &:hover {
    opacity: 1;
  }
}
.form-tab-nav-wrapper-disabled {
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
}
