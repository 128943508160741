/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

#no-hover:hover {
  background-color: #ffffff;
}

.settings-btn {
  width: 100%;

  @media (min-width: 360px) {
    width: 50%;
  }

  @media (min-width: 670px) {
    width: initial;
    margin-right: 1rem;
  }
}

// Dashboard
.dashboard-filter-buttons {
  @media (max-width: 590px) {
    width: 100%;
  }

  @media (max-width: 440px) {
    flex-wrap: wrap !important;
  }
}

// Generic hover underline
.underline_hover {
  :hover {
    text-decoration: underline;
  }
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
  height: 8px;
  border-radius: 5px;
  margin-right: 5px;
}

/* Estilizando o polegar (a parte móvel da barra de rolagem) */
::-webkit-scrollbar-thumb {
  background-color: #555; /* Cor de fundo do polegar */
  border-radius: 53px; /* Raio da borda do polegar */
  border-radius: 10px;
  background-clip: padding-box; // <== make the border work
  min-height: 7px;
}

/* Estilizando o track (a parte fixa da barra de rolagem) */
::-webkit-scrollbar-track {
  margin: 1.5rem;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 500px;
}

.gnv-dropdown {
  .rounded-circle {
    display: none;
  }
}