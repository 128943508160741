.pulseDotContainer {
  display: inline-block;
  position: relative;
}

.dotBack,
.dotFront,
.dot {
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.dotBack {
  animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.dotFront {
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulseRing {
  0% {
    transform: scale(1);
  }
  80%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseDot {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
