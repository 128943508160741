$select-card: "select-card";
$select-card-selected: "select-card-selected";

.#{$select-card} {
  transition: border 0.3s ease-in-out 0s;
  &:hover {
    border-color: rgba(0, 0, 0, 0.3) !important;
  }

  &.#{$select-card-selected} {
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
}
