.switch {
 font-size: 17px;
 position: relative;
 display: inline-block;
 width: 42px;
 height: 24px;
 padding: 5px;
}

.switch input[type="checkbox"] {
 opacity: 1;
 width: 0;
 height: 0;
}

.slider {
 position: absolute;
 cursor: pointer;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0px;
 background: #fff;
 transition: .3s;
 border-radius: 30px;
 border: 1px solid #DFDFDF;
}

.slider:before {
 position: absolute;
 content: "";
 height: 14px;
 width: 14px;
 border-radius: 7px;
 left: 5px;
 right: 5px;
 top: 4px;
 bottom: 0;
 background-color: white;
 box-shadow: 0 2px 5px #999999;
 transition: .4s;
}

input[type="checkbox"]:checked + .slider {
 background-color: #454545;
 border: 1px solid transparent;
}

input[type="checkbox"]:checked + .slider:before {
 transform: translateX(18px);
}

input[type="checkbox"]:disabled + .slider {
  background-color: #DFDFDF;
  border: 1px solid transparent;
  opacity: 0.5;
  cursor: not-allowed;
}

input[type="checkbox"]:disabled + .slider:before {
}