.card-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(xl) {
    padding: 20px 20px;
  }
}

.card-button:hover {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card-button:active,
.card-button:focus {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card-button-container {
  max-width: 360px;
  margin: "auto";
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;

  margin: "auto";
}

.card-button-content-container {
  gap: 1.25rem;
  @include media-breakpoint-down(md) {
    gap: 0.75rem;
  }
}

.card-anchor {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.7;
}
