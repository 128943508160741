$default-row-size: calc(var(--bs-gutter-x) * 0.5);

@mixin row-size($size: $default-row-size, $position: "center") {
  > *:nth-child(odd) {
    padding-right: $size;
    padding-left: if($position == "external", $size, if($position == "internal", 0rem, $default-row-size));
  }

  > *:nth-child(even) {
    padding-left: $size;
    padding-right: if($position == "external", $size, if($position == "internal", 0rem, $default-row-size));
  }
}

@mixin default-row {
  @extend .flex-wrap;
  @extend .d-flex;
}

@mixin row-breakpoint($size: $default-row-size, $position, $breakpoint) {
  @include default-row();
  @include media-breakpoint-up($breakpoint) {
    @include row-size($size, $position);
  }
}

.in-row-sm-75 {
  @include row-breakpoint(0.75rem, "internal", "sm");
}

.row-sm-75 {
  @include row-breakpoint(0.75rem, "internal", "sm");
}

// @for $i from 1 through 4 {
//   $size: 0.25rem * $i;
//   $sizePercentage: $size * 100;

//   // Classes principais
//   -row-#{$sizePercentage} {
//     @include-row-size($size, "center");
//   }

//   .ex-row-#{$sizePercentage} {
//     @include-row-size($size, "external");
//   }

//   .in-row-#{$sizePercentage} {
//     @include-row-size($size, "internal");
//   }

//   // Classes com breakpoint "sm"
//   -row-sm-#{$sizePercentage} {
//     @include-row-breakpoint($size, "center", "sm");
//   }

//   .ex-row-sm-#{$sizePercentage} {
//     @include-row-breakpoint($size, "external", "sm");
//   }

//   .in-row-sm-#{$sizePercentage} {
//     @include-row-breakpoint($size, "internal", "sm");
//   }

//   // Classes com breakpoint "md"
//   -row-md-#{$sizePercentage} {
//     @include-row-breakpoint($size, "center", "md");
//   }

//   .ex-row-md-#{$sizePercentage} {
//     @include-row-breakpoint($size, "external", "md");
//   }

//   .in-row-md-#{$sizePercentage} {
//     @include-row-breakpoint($size, "internal", "md");
//   }
// }
